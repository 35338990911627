<template>
  <notification-item :message="message" @delete-item="deleteItem" @close="close">
    <template v-slot:title>
      <span>{{ $t(`_.title.${excludeType}`) }}</span>
    </template>
    <p class="main-msg">{{ $t(`_.statusMessage.${isLeader ? 'leader' : 'member'}.${excludeType}`, { title, teamName }) }}</p>
    <p v-if="excludeType === 'EXPEL'" class="sub-msg">{{ $t('_.target', { nickname: excludeTarget }) }}</p>
    <p v-if="excludeReason" class="sub-msg">{{ $t('_.reason', { reason: excludeReason }) }}</p>
  </notification-item>
</template>

<script>
import Specific from '@shared/types/Specific';
import NotificationItem from '@shared/components/notification/NotificationItem.vue';

export default {
  name: 'ApplicationExcludeItem',
  components: { NotificationItem },
  lexicon: 'shared.notification.APPLICATION_EXCLUDE',
  props: {
    message: Specific,
  },
  computed: {
    /** @type {ArenaExcludeInfo} */
    exclude() {
      return this.message?.data?.excludeArenaApply || this.message?.data?.excludeUser;
    },
    /** @type {TournamentInfo|{}} */
    tournament() {
      return this.message?.data?.tournament || {};
    },
    excludeType() {
      return this.exclude?.excludedApply.type;
    },
    excludeReason() {
      return this.exclude?.excludedApply.reason;
    },
    excludeTarget() {
      return this.exclude?.user?.nickname || this.$t('allOfTeam');
    },
    title() {
      return this.tournament?.title || this.$t('anonymous.arena');
    },
    teamName() {
      return this.exclude?.team?.name || this.$t('anonymous.team');
    },
    isLeader(){
      return Boolean(this.message?.data?.isLeader);
    }
  },
  methods: {
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    close(){
      this.$emit('close');
    }
  },
};
</script>
